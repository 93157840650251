import React, { createRef, useState } from 'react';

import T from 'i18n';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ActionsButton from '../../common/ActionsButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { formatLabel } from 'utils/formatLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
  faPlusCircle,
  faTrashAlt,
  faEdit,
  faDownload,
  faExchangeAlt,
  faLink,
  faInfoCircle,
  faClone,
  faPaste,
  faEye,
  faFileUpload,
  faIndent,
  faFilter,
  faArrowCircleUp,
} from '@fortawesome/free-solid-svg-icons';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { documentModalActions } from '../enums';
import {
  selectPermissions,
  selectFolderPath,
  selectCustomContainers,
  selectContainers,
  selectDownloadedDocuments,
  selectGenerateHyperlinks,
  selectCurrentFolder,
  selectConfidentialityRing,
  selectCurrentUserConfidentialityRing,
  selectUserAiTools,
  selectCurrentCaseId,
  selectCurrentFile,
  selectDocFinderFilter,
  selectAllowBatchUpload,
} from 'common/selectors';
import history from 'common/history';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import FilterChips from './FilterChips';
import useSearchContext from 'features/common/Search/useSearchContext';
import { setAiToolsFilters } from '../redux/setAiToolsFilters';
import { Button } from 'react-bootstrap';
import { useSetShowDocFinderFilter } from '../redux/setShowDocFinderFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { Spinner } from 'features/common';
import { ClearIcon } from '@mui/x-date-pickers';
import debounce from 'utils/debounce';
import { fetchDocumentsDocFinder } from '../redux/fetchDocumentsDocFinder';

interface TextFieldWithClearProps {
  value: string;
  onEnterPressed?: () => void;
  onClearValue?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
  [key: string]: any;
}

const TextFieldWithClear: React.FC<TextFieldWithClearProps> = props => {
  return (
    <TextField
      {...props}
      variant="standard"
      sx={{
        '& .Mui-focused .MuiIconButton-root': {
          visibility: props.value ? 'visible !important' : 'hidden',
        },
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          props.onEnterPressed && props.onEnterPressed();
        }
      }}
      InputProps={{
        endAdornment: (
          <IconButton
            id="clear-search"
            size="small"
            style={{ padding: '0.3rem', visibility: 'hidden' }}
            onMouseDown={() => {
              props.onClearValue && props.onClearValue();
            }}
          >
            <ClearIcon style={{ fontSize: '1.2rem' }} />
          </IconButton>
        ),
      }}
    />
  );
};

const buttonActions = ({
  selectedRows,
  isPresenterTools,
  permissions = {},
  downloadedDocuments,
  currentFolder,
  openDialog,
  handleShow,
  showGenerateHyperlinks,
  isAllDocuments,
  getFolderSpecificFileDetails,
  dispatch,
  isConfidentialityRing,
  openDialogCR,
  showAiTools,
  caseId,
  sortMode,
  handleSidebar,
  path,
  fileCount,
  allowBatchUpload,
  showSidebar,
}: any) => {
  const {
    folders: {
      canUploadDocuments = undefined,
      canRemoveDocuments = undefined,
      canDownloadDocuments = undefined,
    } = {},
    documents: {
      canUpdateAnyData = undefined,
      canReplace = undefined,
      canGeneratePublicHyperlinks = undefined,
      canSeeFileVersions = undefined,
      canAssignDocsToRings = undefined,
      canCreateAnnotations = undefined,
    } = {},
    hearingRoom: { canPresent = undefined } = {},
  } = permissions;

  const canDownload = canDownloadDocuments;
  const canUpload = canUploadDocuments && canUploadDocuments();
  const canRemove = canRemoveDocuments && canRemoveDocuments();
  const canUpdateData =
    canUpdateAnyData && selectedRows.every((file: any) => canUpdateAnyData(file));
  const canGeneratePublicHyperlinksFile =
    !isPresenterTools && canGeneratePublicHyperlinks && canGeneratePublicHyperlinks();
  const canShowFileVersions = canSeeFileVersions && canSeeFileVersions(selectedRows[0]);
  const assignDocsToRing = canAssignDocsToRings && canAssignDocsToRings(selectedRows[0]);
  const canReplaceFile = canReplace && canReplace(selectedRows[0]);
  const selectedDocumentBeeingDownloaded =
    (selectedRows[0] &&
      downloadedDocuments.find(({ fileId }: any) => fileId === selectedRows[0].id)) ||
    {};

  const handleAiToolsClicked = (files: any) => {
    dispatch(
      setAiToolsFilters({ files: files.map((file: any) => ({ id: file.id, name: file.name })) }),
    );
    history.push(history.location.pathname.replace(/\/case.*/, ``) + `/case/${caseId}/ai-tools`);
  };

  const disableBatchUpload =
    fileCount > 0 || path?.length !== 2 || currentFolder?.folders?.length > 0;
  return [
    canUpload &&
      !isPresenterTools &&
      currentFolder &&
      !currentFolder.readOnly && {
        title: T.translate('generic.uploadFile'),
        handler: openDialog,
        icon: faPlusCircle,
        disabled: showSidebar,
      },
    canUpload &&
      !isPresenterTools &&
      currentFolder &&
      !currentFolder.readOnly &&
      allowBatchUpload && {
        title: T.translate('generic.batchUpload'),
        handler: handleSidebar,
        icon: faArrowCircleUp,
        disabled: disableBatchUpload,
      },
    isConfidentialityRing &&
      canUpload &&
      !isPresenterTools &&
      currentFolder &&
      !currentFolder.readOnly && {
        title: T.translate('generic.uploadFileInner'),
        handler: openDialogCR,
        icon: faPlusCircle,
      },
    canUpload && !isPresenterTools && currentFolder && !currentFolder.readOnly && { divider: true },
    canDownload && {
      id: 'download',
      title: T.translate('generic.download'),
      handler: () => {
        //actionsV
        //   .downloadDocument({ fileId: selectedRows[0].id, fileType: true })
        //   .then(response => {
        //     return response && saveAs(response.blob, response.fileName || selectedRows[0].id);
        //   });
        handleShow(documentModalActions.download);
      },
      icon: faDownload,
      disabled: selectedRows.length !== 1 || !!selectedDocumentBeeingDownloaded.progress,
    },
    (canUpload || canDownload) && { divider: true },
    {
      title: T.translate('generic.copyTo'),
      handler: () => handleShow(documentModalActions.copy),
      icon: faFolderOpen,
      disabled: selectedRows.length < 1,
    },
    canRemove &&
      !isPresenterTools &&
      !currentFolder.readOnly && {
        title: T.translate('generic.removeFrom'),
        handler: () => handleShow(documentModalActions.remove),
        icon: faTrashAlt,
        disabled: selectedRows.length < 1,
      },

    (canRemove || canUpload) && canUpdateData && { divider: true },
    canUpdateData &&
      !isPresenterTools &&
      !currentFolder.readOnly && {
        id: 'updateMeta',
        title: (
          <span style={{ display: 'inline-flex', width: '100%' }}>
            {T.translate('generic.updateMeta')}
            <span className="shortcutsText">{T.translate('common.openUpdateModalShrtcut')}</span>
          </span>
        ),
        subTitle: T.translate('generic.updateMeta'),
        handler: () => handleShow(documentModalActions.updateMeta),
        icon: faEdit,
        disabled: selectedRows.length !== 1,
      },
    canUpdateData &&
      !isPresenterTools &&
      !currentFolder.readOnly &&
      !currentFolder.agreed &&
      sortMode && {
        id: 'changePosition',
        title: `${T.translate('generic.changePosition')}`,
        handler: () => handleShow(documentModalActions.changePosition),
        icon: faIndent,
        disabled: selectedRows?.length < 1,
      },
    canReplaceFile &&
      !isPresenterTools &&
      !currentFolder.readOnly && {
        title: T.translate('generic.replaceFile'),
        handler: () => handleShow(documentModalActions.replace),
        icon: faExchangeAlt,
        disabled: selectedRows.length !== 1,
      },
    {
      title: T.translate('generic.view'),
      handler: () => getFolderSpecificFileDetails(selectedRows),
      icon: faEye,
      disabled: selectedRows.length !== 1,
    },
    (canUpdateData || canReplaceFile) &&
      canGeneratePublicHyperlinksFile &&
      showGenerateHyperlinks &&
      !isAllDocuments && { divider: true },
    canGeneratePublicHyperlinksFile &&
      showGenerateHyperlinks &&
      !isAllDocuments && {
        header: T.translate('generic.generateHyperlinksFrom'),
        title: T.translate('generic.generateFileBasedHyperlinks'),
        handler: () => handleShow(documentModalActions.generateHyperlinks),
        icon: faLink,
        disabled: selectedRows.length !== 1,
      },
    canGeneratePublicHyperlinksFile &&
      showGenerateHyperlinks &&
      !isAllDocuments && {
        title: T.translate('generic.generateTranscriptbasedHyperlinks'),
        handler: () => handleShow(documentModalActions.generateTranscriptsbasedHyperlinks),
        icon: faLink,
        disabled: selectedRows.length !== 1,
      },
    canGeneratePublicHyperlinksFile &&
      showGenerateHyperlinks &&
      !isAllDocuments && {
        title: T.translate('generic.findAndReplaceHyperlinks'),
        handler: () => handleShow(documentModalActions.findAndReplaceHyperlinks),
        icon: faLink,
        disabled: selectedRows.length !== 1,
      },
    canGeneratePublicHyperlinks && showGenerateHyperlinks && !isAllDocuments && { divider: true },
    canPresent &&
      canPresent('public') && {
        id: 'presentToHearingRoom',
        title: (
          <span style={{ display: 'inline-flex', width: '100%' }}>
            {T.translate('generic.present')}
            <span className="shortcutsText">
              {T.translate('common.presentToHearingRoomShortcut')}
            </span>
          </span>
        ),
        subTitle: T.translate('generic.present'),
        handler: () =>
          history.push(
            history.location.pathname.replace(/\/files.*/, ``) +
              `/files/${selectedRows[0].id}?present=public`,
          ),
        icon: faChromecast,
        disabled: selectedRows.length !== 1 || selectedRows[0].private,
      },
    canCreateAnnotations && { divider: true },
    // canMergeDoc && {
    //   title: T.translate('case.merge'),
    //   handler: () => handleShow(documentModalActions.merge),
    //   icon: faLayerGroup,
    //   disabled: selectedRows.length < 1,
    // },
    canCreateAnnotations && {
      id: 'transcriptNotes',
      title: T.translate('generic.transcriptNotes'),
      handler: () => handleShow(documentModalActions.uploadTranscriptNotes),
      icon: faFileUpload,
      disabled: selectedRows.length !== 1,
    },
    // canDownload && { divider: true },
    // canDownload && {
    //   id: 'downloadOriginal',
    //   title: T.translate('generic.downloadOriginal'),
    //   handler: () => {
    //    actionsV
    //       .fetchFileLocation({ fileId: selectedRows[0].id, original: true })
    //       .then(res => {
    //        actionsV
    //           .downloadDocument({
    //             fileId: selectedRows[0].id,
    //             original: res,
    //             fileType: selectedRows[0].type,
    //           })
    //           .then(response => {
    //             response && saveAs(response.blob, response.fileName || selectedRows[0].id);
    //           });
    //       });
    //   },
    //   icon: faDownload,
    //   disabled: selectedRows.length !== 1 || !!selectedDocumentBeeingDownloaded.originalProgress,
    // },
    { divider: true },
    canShowFileVersions && {
      title: T.translate('generic.fileVersions'),
      handler: () => handleShow(documentModalActions.fileVersions),
      icon: faClone,
      disabled: selectedRows.length !== 1,
    },
    isConfidentialityRing &&
      assignDocsToRing && {
        title: T.translate('generic.fileVariants'),
        handler: () => handleShow(documentModalActions.fileVariants),
        icon: faPaste,
        disabled: selectedRows.length !== 1,
      },
    {
      title: T.translate('generic.more'),
      handler: () => handleShow(documentModalActions.moreInfo),
      icon: faInfoCircle,
      disabled: selectedRows.length !== 1,
    },
    showAiTools && { divider: true },
    showAiTools && {
      title: T.translate('case.askAI'),
      handler: () => handleAiToolsClicked(selectedRows),
      icon: {
        type: 'muiIcon',
        component: <AutoAwesomeIcon sx={{ ml: '-0.5rem' }} />,
      },
      disabled: selectedRows.length === 0 || selectedRows.length > 5,
    },
  ];
};

const breadcrumbMeasuredRef = createRef<any>();

export default ({
  filterCategory,
  selectedRows,
  openDialog,
  handleShow,
  isAllDocuments,
  isPresenterTools,
  category,
  getFolderSpecificFileDetails,
  openDialogCR,
  sortMode,
  handleSidebar,
  isDocFinderPage,
  fileCount,
  showSidebar,
  batchUploading,
  filter,
  setFilter,
}: any) => {
  const [collapseBreadCrumb, setCollapseBreadCrumb] = useState(true);
  //@ts-ignore
  const path = useSelector(state => selectFolderPath(state, category));
  const isCustomContainers = useSelector(selectCustomContainers);
  const containers = isCustomContainers && useSelector(selectContainers);
  const permissions = useSelector(selectPermissions);
  const { clearSearch } = useSearchContext();
  const downloadedDocuments = useSelector(selectDownloadedDocuments);
  const showGenerateHyperlinks = useSelector(selectGenerateHyperlinks);
  const currentFolder = useSelector(selectCurrentFolder);
  const currrentUserCR = useSelector(selectCurrentUserConfidentialityRing);
  const allowBatchUpload = useSelector(selectAllowBatchUpload);

  const isConfidentialityRing =
    useSelector(selectConfidentialityRing) && currrentUserCR !== 'noRing';
  const showAiTools = useSelector(selectUserAiTools);
  const breadcrumbWidth =
    (breadcrumbMeasuredRef &&
      breadcrumbMeasuredRef.current &&
      breadcrumbMeasuredRef.current.offsetWidth) ||
    0;
  const caseId = useSelector(selectCurrentCaseId);
  const checkPathForCustomContainers = (val: any) => {
    if (containers) {
      if (val === T.translate('case.trialbooks')) return formatLabel(containers[0]);
      else if (val === T.translate('case.courtBundles')) return formatLabel(containers[1]);
      else if (val === T.translate('case.bundles')) return formatLabel(containers[2]);
      else return val;
    } else return val;
  };

  const dispatch = useDispatch();

  const handleBreadCrumbCollapse = (collapse: boolean) => {
    if (path.length > 2) {
      setCollapseBreadCrumb(collapse);
    } else {
      setCollapseBreadCrumb(collapse);
    }
  };

  const showBreadCrumb = () => {
    return batchUploading ? (
      <>
        <Breadcrumb ref={breadcrumbMeasuredRef}>
          {path.length > 0 && <Breadcrumb.Item active>{`${path[0]}`}</Breadcrumb.Item>}
          <Breadcrumb.Item active>{` ${T.translate('generic.loading')}`}</Breadcrumb.Item>
        </Breadcrumb>
        <Spinner style={{ height: '1.5rem' }} />
      </>
    ) : (
      <div style={{ cursor: 'pointer' }}>
        <Breadcrumb ref={breadcrumbMeasuredRef}>
          {collapseBreadCrumb ? (
            <Breadcrumb ref={breadcrumbMeasuredRef} onClick={() => handleBreadCrumbCollapse(false)}>
              <Breadcrumb.Item active={true}>
                {checkPathForCustomContainers(path[0])}
              </Breadcrumb.Item>
              {path.length > 2 && (
                <Breadcrumb.Item href="" active={true}>
                  ...
                </Breadcrumb.Item>
              )}
              {path.length > 1 && <Breadcrumb.Item active>{path[path.length - 1]}</Breadcrumb.Item>}
            </Breadcrumb>
          ) : (
            <>
              <Breadcrumb.Item onClick={() => handleBreadCrumbCollapse(true)} active={true}>
                {checkPathForCustomContainers(path).join(' / ')}
              </Breadcrumb.Item>
            </>
          )}
        </Breadcrumb>
      </div>
    );
  };

  const { setShowDocFinderFilter, showDocFinderFilter } = useSetShowDocFinderFilter();
  // const { setShowDocFinderBookmarks, showDocFinderBookmarks } = useSetShowDocFinderBookmarks();
  const hasFileOpenned = !!useSelector(selectCurrentFile);
  const hasDocFinderFilter = useSelector(selectDocFinderFilter);

  return (
    <>
      {showBreadCrumb()}
      <PerfectScrollbar
        style={{
          maxWidth: `calc(100% - ${breadcrumbWidth + 120}px)`,
          flexGrow: 1,
        }}
        className="filterChipSection"
        options={{ suppressScrollY: true, useBothWheelAxes: true }}
      >
        <FilterChips filterCategory={filterCategory} clearFilter={clearSearch} />
      </PerfectScrollbar>
      {/* {isDocFinderPage && (
        <Button
          variant="secondary"
          size="sm"
          style={{ marginRight: '0.5rem' }}
          onClick={() => setShowDocFinderBookmarks(!showDocFinderBookmarks)}
        >
          Bookmarks
        </Button>
      )} */}
      {isDocFinderPage && hasDocFinderFilter && !hasFileOpenned && (
        <Tooltip title={showDocFinderFilter && T.translate('filters.cantBeUsedWithFilter')} arrow>
          <div>
            <TextFieldWithClear
              value={filter.term}
              onChange={e => setFilter({ term: e.target.value })}
              onClearValue={() => setFilter({ term: '' })}
              onEnterPressed={debounce(() => {
                if (filter.term?.length > 0)
                  dispatch(fetchDocumentsDocFinder({ search: filter.term }));
              }, 250)}
              disabled={showDocFinderFilter}
              style={{ marginRight: '0.5rem' }}
              placeholder={T.translate('filters.quickFilterPlaceholder')}
            />
          </div>
        </Tooltip>
      )}
      {isDocFinderPage && hasDocFinderFilter && !hasFileOpenned && (
        <Tooltip
          title={T.translate(showDocFinderFilter ? 'filters.hideFilter' : 'filters.showFilter')}
        >
          <Button
            variant="secondary"
            size="sm"
            style={{ marginRight: '0.5rem' }}
            onClick={() => setShowDocFinderFilter(!showDocFinderFilter)}
          >
            <FontAwesomeIcon icon={faFilter} style={{ fontSize: '1rem' }} />
          </Button>
        </Tooltip>
      )}
      <ActionsButton
        buttonActions={buttonActions({
          selectedRows,
          permissions,
          isPresenterTools,
          downloadedDocuments,
          currentFolder,
          openDialog,
          handleShow,
          showGenerateHyperlinks,
          isAllDocuments,
          getFolderSpecificFileDetails,
          dispatch,
          isConfidentialityRing,
          openDialogCR,
          showAiTools,
          caseId,
          sortMode,
          handleSidebar,
          path,
          fileCount,
          allowBatchUpload,
          showSidebar,
        })}
      />
    </>
  );
};
